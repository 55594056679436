import React from 'react'
import PageCreator from '../components/pages/Creator/Creator'
import Layout from '../Layouts/zh-hk'

const creatorZh = ({ location }) => {
  return (
    <Layout location={location}>
      <PageCreator />
    </Layout>
  )
}

export default creatorZh
